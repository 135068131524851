import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getApartmentsPage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "fe33de5f-cf9f-5a9d-9b44-b19ec634a3f4" } }) {
        edges {
          node {
            acf {
              apartmentsHeroHeading
              apartmentsHeroImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      rooms: allWordpressPage(filter: { path: { regex: "//apartamenty/.*/" } }) {
        edges {
          node {
            id
            slug
            acf {
              apartmentSliderHeading
              apartmentSliderImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
