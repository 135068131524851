import React from 'react';

import { getApartmentsPage } from './apartments.query';

import Container from '../../components/Container/Container';
import Hero from '../../components/Hero/Hero';
import Card from '../../components/Card/Card';

const Apartments = () => {
  const { rooms, allWordpressPage } = getApartmentsPage();
  const { apartmentsHeroHeading, apartmentsHeroImages } = allWordpressPage.edges[0].node.acf;

  return (
    <>
      <Hero heading={apartmentsHeroHeading} slides={apartmentsHeroImages} />
      <Container rowTablet justifyCenterTablet padding>
        {rooms &&
          rooms.edges.map(({ node }) => (
            <Card
              url={`/apartamenty/${node.slug}/`}
              key={node.id}
              heading={node.acf.apartmentSliderHeading}
              fluid={
                node.acf.apartmentSliderImages &&
                node.acf.apartmentSliderImages[0].image.localFile.childImageSharp.fluid
              }
            />
          ))}
      </Container>
    </>
  );
};

export default Apartments;
